// Type
import { OperationType } from "types/environment";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { PaymentConditions } from "types/payment-conditions";

// Utils
import { parseMutationDate } from "utils/parseMutationDate";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

export const defaultValues: AddSaleGeneralDocumentFilterValues = {
  IvaDocumentType: null,
  productDiscount: null,
  saleDiscount: null,
  noGravado: null,
  fleteFex: null,
  seguroFex: null,
  date: parseMutationDate(new Date()),
  detailDocument: {
    quantity: 1,
    salePrice: 1,
  },
  recintoFiscal: null,
  regimenExportacion: null,
  incoterms: null,
  extension: {
    receiverName: null,
    receiverDocument: null,
    observations: null,
  },
  idClient: null,
  clientCompany: null,
  quantity: 1,
  products: null,
  salePrice: null,
  productCodes: null,
  calcuations: null,
  idIvaDocument: null,
  idPaymentType: null,
  operation_type: {
    label: OperationType.GRAVADAS,
    value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.GRAVADAS),
  },
  idCashier: null,
  idSalePoint: null,
  idCostCenter: null,
  notifyTo: [],
  email: null,
  conditionPayment: { label: capitalizeFirstLetter(PaymentConditions.CONTADO.toLowerCase()), value: PaymentConditions.CONTADO },
  taxes: null,
  paymentMethods: null,
  measurementUnit: null,
  type: null,
};

export const initialStateActiveVentas = {
  ventaRechazada: false,
  ventaCompletada: false,
  paymentMethod: false,
  registerDocument: false,
  InventaryConfig: false,
};
