// Constants
import { IVA_AMOUNT, RETENTION_PERCEPTION } from "constants/finantial";

// Types
import { Company } from "types/company";
import { ContribuyenteTypes } from "types/contribuyenteTypes";
import { ClientCompany } from "types/documents";
import { IvaDocument, IvaDocumentTypes } from "types/iva-document";

export const calculatePerception = (totalGravados: number, company: Company, clientCompany: ClientCompany, ivaDocument: IvaDocument) => {
  if (company.contribuyenteType === ContribuyenteTypes.GRANDE && clientCompany.contribuyenteType !== ContribuyenteTypes.GRANDE) {
    if (ivaDocument?.name_document === IvaDocumentTypes.FAC) {
      return (totalGravados / (1 + IVA_AMOUNT)) * RETENTION_PERCEPTION;
    } else if (ivaDocument?.name_document === IvaDocumentTypes.CCF) {
      return totalGravados * RETENTION_PERCEPTION;
    }
  }
  return 0;
};
