import React, { createContext, useState, useContext, useEffect } from "react";

// hooks
import { useLocalStorage } from "hooks/useLocalStorage";

// Types
import { CostCenter, SalePoint } from "types/sells";

// Libs
import { format } from "fecha";

export interface Props {
  children: React.ReactNode;
}
type sellsConfig = {
  costCenter: CostCenter | null;
  selectedSalePoint: SalePoint | null;
  salePoints: SalePoint[] | null;
  cashier: string | null;
  emisionStartDate: string | null;
  emisionEndDate: string | null;
  endDateSale?: string | null;
};

export interface SellsCofigProviderProps {
  sellsConfig: sellsConfig;
  setSellsConfig: React.Dispatch<React.SetStateAction<sellsConfig>>;
  resetSellsConfig: () => void;
}

const SellsConfigContext = createContext<SellsCofigProviderProps>({
  sellsConfig: {
    costCenter: null,
    selectedSalePoint: null,
    salePoints: null,
    cashier: null,
    emisionStartDate: null,
    emisionEndDate: null,
    endDateSale: null,
  },
  resetSellsConfig: () => {},
  setSellsConfig: () => {},
});

export const useSellsConfigContext = () => {
  return useContext(SellsConfigContext);
};

export const SellsConfigContextProvider = ({ children }: Props) => {
  const initialSellsConfig: sellsConfig = {
    costCenter: null,
    selectedSalePoint: null,
    salePoints: null,
    cashier: null,
    emisionStartDate: null,
    emisionEndDate: null,
    endDateSale: null,
  };

  const [sellsConfig, setSellsConfig] = useLocalStorage("sellsConfig", initialSellsConfig);

  const value = {
    sellsConfig,
    setSellsConfig,
    resetSellsConfig: () => setSellsConfig(null),
  };

  return <SellsConfigContext.Provider value={value}>{children}</SellsConfigContext.Provider>;
};
