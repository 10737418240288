import { useState } from "react";

export function useLocalStorage<T>(keyName: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value) as T;
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue: React.Dispatch<React.SetStateAction<T>> = (newValue) => {
    // Usar la versión callback de setStoredValue
    setStoredValue((prevState) => {
      // 1. Si newValue es una función (callback de React),
      //    la invocamos pasándole el estado previo.
      // 2. Si NO es función, es un valor de tipo T.
      const valueToStore = typeof newValue === "function" ? (newValue as (prevState: T) => T)(prevState) : newValue;

      // Guardar en localStorage
      window.localStorage.setItem(keyName, JSON.stringify(valueToStore));

      // Actualizar el estado con ese valor
      return valueToStore;
    });
  };

  return [storedValue, setValue];
}
