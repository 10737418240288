// Graphql
import { OperationVariables, QueryResult, useMutation } from "@apollo/client";

// Mutations
import { RE_SEND_SALE } from "apollo/mutations/sales/reSendSale";

// Context
import { useAddSellContext } from "context/AddSellContext";

// Libs
import { FormikState } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Types
import { SerializationKeys } from "types/apollo";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { PaymentConditions } from "types/payment-conditions";
import { SaleInformation } from "types/saleInformation";

interface Props {
  freeSale: boolean;
  setSaleInformation: React.Dispatch<React.SetStateAction<SaleInformation>>;
  handleClose: (value: ActiveValues) => void;
  handleOpen: (value: ActiveValues) => void;
  dataGetGeneralDocuments: QueryResult<any, OperationVariables>;
}

export const useHandleReSendSale = ({ freeSale = false, setSaleInformation, handleOpen, handleClose, dataGetGeneralDocuments }: Props) => {
  const navigate = useNavigate();

  const {
    global: { products, totalToPay, retentionISR, subTotal, paymentMethods, retention, perception1, retention1, perception, iva },
    resetGlobal,
  } = useAddSellContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  const [reSendSale, dataReSendSale] = useMutation(RE_SEND_SALE, { context });

  const handleReSendSale = async (values: AddSaleGeneralDocumentFilterValues, resetForm: (nextState?: Partial<FormikState<AddSaleGeneralDocumentFilterValues>>) => void, isSimpleBilling?: boolean) => {
    const dataReSendSale: any = {
      idClient: values?.clientCompany?.idClientCompany,
      retention1: retention,
      perception1: perception,
      renta: parseFloat(retentionISR.toFixed(2)),
      date: values?.date,
      idIvaDocument: values?.IvaDocumentType?.id,
      idPaymentType: 5,
      idCashier: dataGetGeneralDocuments?.data?.getGeneralDocuments?.data[0]?.cashier?.idEmployeeCompany,
      idSalePoint: dataGetGeneralDocuments?.data?.getGeneralDocuments?.data[0].salePoint?.id,
      idCostCenter: dataGetGeneralDocuments?.data?.getGeneralDocuments?.data[0].costCenter?.id,
      notifyTo: [],
      conditionPayment: typeof values.conditionPayment === "object" ? values.conditionPayment?.value.toUpperCase() : values.conditionPayment.toUpperCase(),
      isSimpleBilling: (freeSale || isSimpleBilling) ?? false,
      detailDocuments: products.map((product: any) => {
        return {
          name: product?.name,
          discount: 0,
          idArticle: freeSale || isSimpleBilling ? null : product?.id,
          articleCode: product?.barCode,
          articleType: product?.type === "Otros" ? product?.type.slice(0, -1).toUpperCase() : product?.type.toUpperCase(),
          quantity: product?.quantity,
          salePrice: product?.unitPrice,
          idPriceList: product?.idPriceList,
          totalPrice: product?.totalPrice,
          ivaOperationType: product?.operation_type.toUpperCase(),
          description: product?.description ?? "",
          measurementUnitCode: product?.measurement?.code,
          measurementUnitName: product?.measurement?.name,
        };
      }),
      taxes: { iva: iva },
      paymentMethods:
        typeof values.conditionPayment === "object" && values.conditionPayment?.value === PaymentConditions.CREDITO
          ? []
          : paymentMethods.map((item) => ({
              amount: item?.amount,
              paymentMethodCode: item?.paymentMethodCode,
              paymentOperationType: item?.paymentOperationType,
              idPaymentmethod: item?.selectedPaymentMethod?.id,
            })),
      extension: {
        receiverName: values.extension?.receiverName,
        receiverDocument: values.extension?.receiverDocument,
        observations: values.extension?.observations,
      },
      calcuations: { subTotal: subTotal, totalAmountOperation: totalToPay, totalToPay: totalToPay },
    };

    await reSendSale({
      variables: {
        generationCode: dataGetGeneralDocuments?.data?.getGeneralDocuments.data[0]?.generationCodeDte,
        idSaleorigin: dataGetGeneralDocuments?.data?.getGeneralDocuments.data[0]?.id,
        data: dataReSendSale,
      },
    })
      .then(({ data }) => {
        if (data.resendSale.isSuccess) {
          if (data.resendSale.data.status === "APROBADO") {
            toast.success("DTE corregido correctamente");
            navigate("/ventas");
            resetGlobal();
            resetForm({});
          } else {
            toast.error(`Ocurrió un error al corregir venta ${data.resendSale.data?.mhObservations}`);
            setSaleInformation({
              errorInformation: {
                mhObservations: data.resendSale.data?.mhObservations,
                nameClient: data.resendSale.data?.clientCompany?.name,
                generationCodeDte: data?.resendSale?.data?.generationCodeDte,
              },
            });
            handleOpen(ActiveValues.ventaRechazada);
          }
        } else {
          toast.error(`Ocurrió un error al corregir la venta  \n ${data.resendSale?.message?.detail}`);
        }
      })
      .catch((err) => {
        console.log("err", err);

        toast.error(`Ocurrió un error al corregir la venta \n Por favor confirma que los campos son correctos e intente nuevamente o contacta a soporte`);
      })
      .finally(() => {
        handleClose(ActiveValues.paymentMethod);
      });
  };

  return { handleReSendSale, dataReSendSale };
};
