// Constants
import { RETENTION_PERCEPTION, IVA_AMOUNT } from "constants/finantial";

// Types
import { Company } from "types/company";
import { ClientCompany } from "types/documents";
import { ContribuyenteTypes } from "types/contribuyenteTypes";
import { IvaDocument, IvaDocumentTypes } from "types/iva-document";

export const calculateRetention = (totalGravados: number, company: Company, clientCompany: ClientCompany, ivaDocument: IvaDocument) => {
  if (clientCompany.contribuyenteType === ContribuyenteTypes.GRANDE && company.contribuyenteType !== ContribuyenteTypes.GRANDE) {
    if (ivaDocument?.name_document === IvaDocumentTypes.FAC) {
      return (totalGravados / (1 + IVA_AMOUNT)) * RETENTION_PERCEPTION * -1;
    } else if (ivaDocument?.name_document === IvaDocumentTypes.CCF) {
      return totalGravados * RETENTION_PERCEPTION * -1;
    }
  }
  return 0;
};
